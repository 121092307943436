
import { Options, Vue } from 'vue-class-component';

import { AxiosError, AxiosResponse } from 'axios';

import requests from '@/requests';
import methods from '@/methods';
import { LoginResponse } from '@/interface/requests.interface';

@Options({})
export default class AuthLogIn extends Vue {
  public user = {
    username: '',
    password: '',
  }

  public loader = false;

  public errors: string[] = []

  public login(): void {
    this.loader = true;

    requests.auth.login(this.user)
      .then((res: AxiosResponse<LoginResponse>) => {
        this.$store.commit('setupToken', { hijacking: false, token: res.data.token });

        this.getProfile();
      })
      .catch((err: AxiosError<{ detail: 'Invalid username/password.' }>) => {
        this.errors = [];
        this.errors.push(err.response!.data.detail);

        this.loader = false;
      });
  }

  private getProfile(): void {
    requests.profile.getProfile()
      .then((res) => {
        methods.store.setupProfile(res.data);

        if (res.data.tutorial_completed) {
          this.$router.push('/dashboard');
        } else {
          this.getListCompletedTutorial();
        }
      })
      .catch((err) => { this.loader = false; });
  }

  private getListCompletedTutorial(): void {
    requests.profile.getListCompletedTutorial().then((res) => {
      this.$store.commit('set', { key: 'tutorial', payload: res.data });
      this.$router.push('/dashboard');
    });
  }
}
